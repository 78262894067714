<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Pre-Lab Assignment 3</h2>

      <p class="mb-n3">
        This quiz is intended to prepare you to perform the experiment. Therefore, you must earn
        <b>AT LEAST 80%</b> of the marks for the quiz BEFORE arriving to complete the experiment or
        you will not be admitted into the lab.
      </p>

      <v-radio-group v-model="inputs.requirements" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRequirements"
          :key="'pt-0-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        a) In general, for a reaction at equilibrium, if we consume one of the reactants, the
        reaction will shift in the direction that _____________________ :
      </p>

      <v-radio-group v-model="inputs.inputA" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsA"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) A patient is found to have a blood pH of
        <number-value :value="pHB" unit="\text{.}" />
        Which of the following treatments would you suggest?
      </p>

      <v-radio-group v-model="inputs.inputB" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsB"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) The kidneys function to help maintain blood pH. In one process, they convert
        <chemical-latex content="CO2" />
        in the blood into
        <chemical-latex content="HCO3^-\text{.}" />
        This would _____________________ :
      </p>

      <v-radio-group v-model="inputs.inputC" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsC"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        d) In this experiment, we will produce aqueous chemical waste which should be disposed of in
        what way? Select all correct answers.
      </p>

      <p v-for="option in optionsD" :key="'pt-4-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputD" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-6">
        e) The second part of this experiment can be understood more easily if, before the
        experiment, I read about _____________________ (select all correct answers):
      </p>

      <p v-for="option in optionsE" :key="'pt-5-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.inputE" :value="option.value" class="pl-8 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'UOttawaPrelab3',
  components: {ChemicalLatex, NumberValue, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        requirements: null,
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: [],
        inputE: [],
      },
      optionsRequirements: [
        {text: 'I understand and agree to the quiz requirement', value: 'agree'},
        {text: 'I do not agree to the quiz requirement', value: 'disagree'},
      ],
      optionsA: [
        {text: 'produces more reactants', value: 'formMoreReacts'},
        {text: 'produces more products', value: 'formMoreProducts'},
        {text: 'does nothing', value: 'doesNothing'},
      ],
      optionsB: [
        {text: 'Encourage deep breathing', value: 'deepBreathing'},
        {text: 'Do not encourage deep breathing', value: 'noDeepBreathing'},
      ],
      optionsC: [
        {text: 'not change the blood pH', value: 'noChange'},
        {text: 'lower the blood pH', value: 'lowerPH'},
        {text: 'raise the blood pH', value: 'raisePH'},
      ],
      optionsD: [
        {text: 'Thrown in the organic waste container', value: 'organicWaste'},
        {text: 'Thrown in the container marked aqueous waste', value: 'aqueousWaste'},
        {text: 'Thrown down the drain', value: 'drain'},
        {
          text: 'Disposed of in the container marked silver waste if there is silver ion in the mixture',
          value: 'silverWaste',
        },
        {text: 'Evaporated to dryness and then wiped clean', value: 'evaporatedToDryness'},
      ],
      optionsE: [
        {text: 'equilibrium', value: 'eqm'},
        {text: 'complex formation', value: 'complex'},
        {text: 'solubility equilibria', value: 'solEqm'},
        {text: "Le Châtelier's principle", value: 'leChat'},
      ],
    };
  },
  computed: {
    pHB() {
      return this.taskState.getValueBySymbol('pHB').content;
    },
  },
};
</script>
<style scoped></style>
